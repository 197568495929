// MATTE IMAGE
@mixin img-matte($height: 5em) {
    text-align: center;
    img {
        height: $height;
        width: auto;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
