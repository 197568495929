.modal-backdrop:not(.video-dark) {
    background-color: $white;
}

.modal-content {
    @include border-radius($border-radius);
    .close {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}

.video-modal {
    .modal-content {
        background-color: transparent;
        border: none;
        box-shadow: none;
        border-radius: $border-radius;
        .close {
            top: -9px;
            right: 35px;
            color: $white;
            font-size: 2rem;
        }
    }
}

// blur effect on the site body
body {
    mxc-root {
        filter: none;
    }
}
.modal-open {
    mxc-root {
        height: 100vh;
        filter: blur(5px);
    }
}