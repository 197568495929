

/* Header */
.block-v2-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    position: relative;

    &--title{
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 24px;
        flex: 1;
    }

    &--sort{
        display: none;
    }

    &--tabs{
        padding: 0;
        margin: 0;


        li{
            display: inline-block;
            list-style-type: none;
            margin: 0 15px;
            color: $secondary-black;
            font-size: 0.9rem;
            line-height: 24px;
            font-weight: 500;
            cursor: pointer;

            &:last-of-type{
                margin-right: 0;
            }

            & > span{
                display: block;
                padding: 10px 8px;

                & > label{
                    padding: 0;
                    margin: 0;
                }
            }

            &.active > span{
                border-bottom: solid $red 2px;
            }

            &:last-of-type{
                margin-right: 0;
            }
        }
    }

    @media (max-width: map_get($grid-breakpoints, 'sm')) {
        /*
        &--sort{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            color: $blue;
            width: 60px;

        }*/
        &--tabs{
            /*
            display: none;
            position: absolute;
            top: 30px;
            background-color: $white;
            right: 0;
            border: solid $secondary-white 1px;
            z-index: 10;

            &.displayed{
                display: block;
            }

            li {
                border:none !important;

                &.active{
                    font-weight: bold;
                }
            }
            */
            .block-button{
                display: none;
            }
        }
    }
}

/* Body */
.block-v2-body{
    padding: 0;
    display: block;
    width: 100%;
}

/* Block buttons */
.block-button{
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px $blue;
  outline: none;
  transition: all .2s;
  text-align: center;
  background-color: $blue;
  color: $white;
  text-decoration: none;

  &:hover, &:active, &.full{
      text-decoration: none;
      color: $white;
  }

  &.full{
      display: block;
      width: 100%;
  }
}

.block-button-alt{
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px $gray-custom-2;
  outline: none;
  transition: all .2s;
  text-align: center;
  background-color: transparent;
  color: $blue-dark;
  text-decoration: none;

  &:hover, &:active, &.full{
      text-decoration: none;
      background-color: $blue-lightest-hover;
  }

  &.full{
      display: block;
      width: 100%;
  }
}

.mobile-only{
  display: none;
}

@media (max-width: map_get($grid-breakpoints, 'sm')) {
  .hidden-mobile{
      display: none !important;
      padding: 0 !important;
      margin: 0 !important;
  }

  .mobile-only{
      display: block;
  }
}

.block-v2{
  padding: 0 0 1.25rem 0;
  /* Links */
  a.link{
    font-family: $open-sans-regular-font-family;
    color: $blue;
    font-weight: $font-weight-bold;
    font-size: 0.875rem;
    line-height: 1.5rem;
    &:hover, :active, :focus {
      text-decoration: none;
    }
  }
  /* Border */
  &--with-border{
    padding: 1.56rem;
    padding-bottom: 1.25rem;
    border: .0625rem solid $gray-lighter-2;
    border-radius: .5rem;
  }
}


