.card {
    @include box-shadow();
    overflow: hidden;

    // favorite icon position
    mxc-favorite,
    .mxc-favorite {
        position: absolute;
        top: 1em;
        right: 1em;
    }

    // download icon position
    .download-icon {
        position: absolute;
        top: 0.65em;
        right: 2.25em;
    }

    .card-title {
        @extend .h3;
        margin: 0;
        line-height: normal;
        a:hover.underline {
            text-decoration: underline;
        }
    }

    // // fixed height cards on desktop
    // @include media-breakpoint-up(sm) {
    .card-footer {
        height: 3em;
        overflow: hidden;
    }

    // when there is a second footer we add a bgcolor
    .card-footer + .card-footer {
        background: $gray-lightest;
    }

    // POST CARDS
    &.post-card {
        .card-footer {
            height: 24em;
            @media (max-width: map-get($grid-breakpoints, sm)) {
                .h3 {
                    line-height: 1.3;
                }
                .card-text {
                    line-height: 1.3;
                }
            }
            @include media-breakpoint-up(sm) {
                height: 12em;
            }
            @include media-breakpoint-up(md) {
                height: 15em;
            }
            @include media-breakpoint-up(lg) {
                height: 11em;
            }
            @include media-breakpoint-up(xl) {
                height: 10em;
            }
        }
    }
    &.user-card,
    &.corporate-card,
    &.office-card {
        // fix for z-index issue where icons are still over pictures
        .action-icon{
            z-index: 1;
        }
        box-shadow: $box-shadow-inactive;
        &:hover &:focus {
            box-shadow: $box-shadow-active;
        }
        .card-text {
            line-height: normal;
        }
        @include media-breakpoint-up(sm) {
            .card-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    // USER ROSTER CARDS
    &.user-card,
    &.corporate-card {
        .card-footer {
            height: 8em;
            + .card-footer {
                height: 11em;
            }
            @include media-breakpoint-up(sm) {
                height: 4em;
                + .card-footer {
                    height: 6em;
                }
            }
            @include media-breakpoint-up(sm) {
                height: 5em;
                + .card-footer {
                    height: 8em;
                }
            }
            @include media-breakpoint-up(lg) {
                height: 4em;
                + .card-footer {
                    height: 6em;
                }
            }
        }
        mxc-favorite {
            position: absolute;
            right: 0;
            z-index: 5;
        }
    }

    // OFFICE ROSTER CARDS
    &.office-card {
        .card-footer {
            height: 4em;
            + .card-footer {
                height: 12em;
            }
            @include media-breakpoint-up(sm) {
                + .card-footer {
                    height: 6em;
                }
            }
            @include media-breakpoint-up(sm) {
                + .card-footer {
                    height: 8em;
                }
            }
        }
        mxc-favorite {
            position: absolute;
            right: 1rem;
            z-index: 5;
        }
    }
}

// hover lift
.card:not(article):not(aside):not(.short-profile):not(.shortener-card):not(.card-horizontal) {
    transform: scale(0.99) translateZ(0);
    transition: all 0.3s ease-out;
    &:hover {
        transform: scale(1);
        transition: all 0.3s ease-in;
        @include box-shadow(0, 0.5em, 1em, $shadow-color);
    }
}

.description-popover {
    width: 85%;
}

// new card styles
mxc-apps-tile,
mxc-post-tile,
mxc-popular-resource-tile {
    //bookmark styles for new tiles till roster cards are updated
    .card:not(.card-horizontal):not(.popular-resource-card) {
        box-shadow: $box-shadow-inactive;
        border: none;
        background-clip: unset;
        border-radius: 0.5em;
        &.border-primary {
            border: 2px solid;
        }
        &:hover,
        &:focus {
            box-shadow: $box-shadow-active;
        }
    }
    .card-horizontal {
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
        margin-top: 5.625rem;
        &.featured {
            @include media-breakpoint-down(sm) {
                box-shadow: $box-shadow-md;
                border-radius: $border-radius-md;
            }
            @include media-breakpoint-up(md) {
                margin-top: 2.25rem;
                .card-title {
                    font-size: 1.875rem;
                }
                .card-content {
                    padding-left: 3.125rem;
                }
            }
            img {
                @include media-breakpoint-down(sm) {
                    border-radius: 1rem 1rem 0 0;
                }
            }
            .card-title,
            .department {
                margin-bottom: $margin-md;
            }
        }
        .department {
            margin-bottom: $margin-sm;
            color: $gray-dark;
        }
        .card-title {
            font-size: 1.313rem;
            line-height: 26px;
            margin-bottom: $margin-sm;
        }
        .card-text {
            font-size: $h3-font-size;
            line-height: 28px;
        }
        .card-body {
            overflow: hidden;
        }
        img {
            border-radius: $border-radius-md;
        }
        .card-img-overlay {
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            border-radius: $border-radius-md;
            button {
                height: 3rem;
            }
            &:hover .overlay-icon {
                font-size: 2.3rem;
            }
            .overlay-icon {
                color: $white;
                font-size: 2rem;
            }
            @include media-breakpoint-down(sm) {
                padding: 0.25rem;
                button {
                    height: 100%;
                }
            }
        }
        .action-bar {
            height: 2.5rem;
            border-radius: $border-radius-md;
            cursor: default;
            .btn {
                min-width: 3em;
                height: inherit;
            }
            .post-type-text {
                margin-top: $margin-sm;
            }
            @include media-breakpoint-down(sm) {
                .post-icon {
                    margin-left: 1em;
                }
            }
        }
        // hover title highlight
        &:hover {
            cursor: pointer;
            .card-title {
                text-decoration: underline;
            }
        }
        mxc-favorite,
        .download-icon {
            position: relative;
            top: 0;
            right: 0;
            z-index: 5;
        }
    }
    .card-footer {
        background-color: transparent;
        border-top: 0;
        @include media-breakpoint-down(sm) {
            .action-bar {
                height: 3em;
                .btn {
                    min-width: 3em;
                    height: inherit;
                }
            }
        }
    }

    fa-icon {
        svg {
            filter: none !important;
            line-height: inherit;
        }
        &:not(.text-primary):not(.post-icon):not(.overlay-icon):not(.popular-resource-icon) svg {
            color: $gray;
            stroke: none;
            &:hover {
                color: darken($gray, 25%);
                transform: scale(1.2);
                stroke: $white;
            }
        }
    }
}
