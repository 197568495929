// fancy toggle switch
@mixin toggle-switch(
    $track-height           : 46px,
    $indicator-color        : #{$gray},
    $indicator-color-active : #{$primary},
    $track-color            : #{$white},
    $track-color-active     : #{$white}
) {
    $track-width    : $track-height * 2;
    $indicator-size : $track-height - 6px;
    position: relative;
    display: inline-block;
    margin: 0;

    input { display: none; }

    // the toggle track
    .switch-toggle {
        width: $track-width;        // WIDTH OF THE BAR
        height: $track-height;      // HEIGHT OF THE BAR
        position: relative;
        cursor: pointer;
        border: solid 1px $gray;
        border-radius: $track-height * 2;
        background-color: $track-color;
        transition: 0.4s;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        // the toggle indicator
        &::before {
            height: $indicator-size;
            width: $indicator-size;
            left: 2px;  // CIRCLE X POSITION START
            top: 50%;
            transform: translateY(-50%);
            background-color: $indicator-color;
            border-radius: 50%;
            border: none;
            position: absolute;
            content: '';
            transition: 0.4s;
        }
        &::after {
            content: 'OFF';
            float: right;
            margin: 12px 12px 0 0;
            color: $gray;
            font-size: 60%;
        }
    }

    // toggle track active
    input:checked + .switch-toggle {
        background-color: $track-color-active;
        // toggle indicator active
        &::before {
            background-color: $indicator-color-active;
            border-color: $track-color-active;
            transform: translate(($track-width - ($indicator-size) - 6), -50%);  // CIRCLE X POSITION END
        }

        &::after {
            content: 'ON';
            float: left;
            margin: 12px 0 0 12px;
            color: $primary;
        }
    }

    // toggle track focus
    input:focus + .switch-toggle {
        box-shadow: 0 0 1px $primary;
    }

    // text labels (optional)
    .toggle-label {
        position: absolute;
        line-height: $track-height;
        color: $gray;
        top: 0;
        @include truncate();
        &.right { margin-left: $track-width + 10px; }
        &.left { margin-right: $track-width + 10px; }
    }
}
