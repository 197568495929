// global print style
@media print {

    // try to hide print header and page numbers
    @page {
        size: 8.5in 11in portrait;
        margin: 0.5in 0.5in 0.75in;
    }

    main {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        background: none !important;
    }

    // reset colors for printing
    body {
        background: none !important;
        color: #000000 !important;
    }
    [class*='text-'] {
        color: #000000 !important;
    }
    [class*='bg-'] {
        background: transparent !important;
    }

    // hide all these things when printing
    mxc-header,
    .subheader,
    .nav,
    .btn,
    .badge {
        display: none !important;
    }

    // compensate for hidden header
    ng-component {
        padding-top: 0 !important;
    }

    // no shadows for printing
    * {
        box-shadow: none !important;
        filter: none !important;
    }

    a {
        text-decoration: none !important;
    }

    // type out link href in body text
    p a::after {
        content: '  (' attr(href) ')';
        font-size: $font-size-base;
        font-family: $font-family-base;
    }

    // safari bug
    .container {
        width: auto !important;
    }

    // EULA and PRIVACY POLICY SPECIFIC PRINT STYLES
    // unfortunately angular does not seem to correctly parse the @print media inside components
    mxc-header-alt {
        .jumbotron {
            padding-top: 0;
            padding-bottom: 0.125in;
            margin-bottom: 0.25in;
            border-bottom: solid 1pt #bbbbbb;
            text-align: center;
            background: none;
        }

        img {
            width: 50%;
            margin: 0 auto;
            padding: 0;
        }
    }

    .version {
        position: fixed;
        bottom: 0;
        right: 0;
    }

    // PROFILE SPECIFIC
    #profile-page {
        [class*='card-'], .card {
            border: transparent !important;
            border-radius: 0 !important;
            padding: 0 !important;
        }
        .card-img {
            margin-right: 0.125in;
        }
        aside {
            margin-top: 0.125in;
        }
        .h1, hr {
            display: none !important;
        }
        .section-heading {
            margin: 0.125in 0;
        }
    }

    // POST BODY
    #single-post {
        [class*='card-'], .card {
            border: transparent !important;
            border-radius: 0 !important;
            padding: 0 !important;
        }
        .single-post-hero {
            outline: none !important;
        }
        .card-body {
            margin-top: 0.25in !important;
        }
        .card-footer {
            display: none !important;
        }
    }
}