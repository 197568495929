// CIRCLE ICON OR PHOTO
@mixin circle-content($radius: 2.5em) {
    height: $radius;
    width: $radius;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    fa-icon, .fa {
        font-size: calc(#{$radius} / 1.5);
    }
    img {
        max-width: $radius;
        max-height: $radius;
    }
}
