// shortener accordion groups
#accordion {
    .card-header-group {
        border: none;
    }
    .collapse, .collapse.show {
        display: block !important;
    }
    .collapse {
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-out 0.3s;
    }
    .collapse.show {
        max-height: 50em;
        transition: max-height ease-in 0.4s;
        border-top: solid 1px $border-color;
    }
}