// APPLY MONTSERRAT FONT BEYOND HEADING TAGS
.lead,
.btn,
.nav-tabs,
.montserrat {
    font-family: $headings-font-family;
}

.open-sans-regular {
    font-family: $open-sans-regular-font-family;
}

.open-sans-semibold {
    font-family: $open-sans-semibold-font-family;
    font-weight: 600;
}

// H5 IS A SECTION HEADING
.section-heading {
    font-family: $headings-font-family;
    font-weight: $font-weight-light;
    color: $dark;
    letter-spacing: $h5-font-size / 2;
    padding-bottom: $card-spacer-y;
    margin-top: $card-spacer-y * 4;
    margin-bottom: $card-spacer-y * 2;
    border-bottom: solid 1px $border-color;
    text-transform: uppercase;
    width: 100%;
}
