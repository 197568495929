// RESPONSIVE FONT SIZING
/* stylelint-disable declaration-property-unit-blacklist */

html {
    font-size: 14px;
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
    // @include media-breakpoint-up(lg) {
    //     font-size: 18px;
    // }
    // @include media-breakpoint-up(xl) {
    //     font-size: 20px;
    // }
}

/* stylelint-enable */

// RESPONSIVE GUTTER WIDTH
@media (max-width: map-get($grid-breakpoints, sm)){
    header.container-fluid {
        padding-right: $grid-gutter-width / 4;
        padding-left: $grid-gutter-width / 4;
    }
    .row:not(.no-gutters) {
        margin-right: -$grid-gutter-width / 4;
        margin-left: -$grid-gutter-width / 4;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*='col-'] {
        padding-right: $grid-gutter-width / 4;
        padding-left: $grid-gutter-width / 4;
    }
}

// FONT SMOOTHING
html,
body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//background image
body {
    // Below commented as business may ask for this image back
    //background-image: url('#{$S3_PATH}/img/bg.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

// ENSURE CONTENT WRAPPER FILLS VIEWPORT FOR CLICK OUTSIDE FEATURES
main {
    min-height: 100vh;
}

// ENSURE ANGULAR TAGS HAVE LAYOUT
mxc-root,
ng-component,
mxc-header-alt,
mxc-header,
mxc-scoped-search,
mxc-search,
mxc-footer,
mxc-results-chunk,
mxc-apps-tile {
    position: relative;
    display: contents;
}

// FIX CURSORS
a:not(.disabled), select, label,
.btn, input:read-only.custom-file-input,
.clickable, button:not(:disabled) {
    cursor: pointer;
}

.btn.disabled,
.btn:disabled,
input:disabled,
input:read-only {
    cursor: not-allowed;
    &:hover {
        text-decoration: none;
    }
}

// SMOOTH ALL HOVERS
a, a .fa,
button, .btn,
input[type='submit'],
.nav-link, .nav-item,
.table-hover tr {
    transition: 0.4s ease;
    &:hover {
        transition: 0.4s ease;
    }
}

// FIX ALIGNMENT BUG ON INLINE BLOCK
.d-inline-block {
    vertical-align: text-top;
}

// FIX BOOTSTRAP TEXT-TRUNCATE BUG
.text-truncate {
    display: block;
}

// DISABLE CSS TRANSITIONS FOR TESTING
.disable-transitions * {
    transition: none !important;
}

//add a negative margin to the top of search of certain content rows
.row.negative-margin {
    margin-top: -50px;
}

//needed for badges that go on top of content
.badge-float {
    position: relative;
    z-index: 1;
}
