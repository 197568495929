.nav-tabs {
    text-transform: capitalize;
    border-bottom: 0;
    .nav-link {
        padding-left: 0;
        padding-right: 0;
        margin-right: 2em;
        &.active {
            background: inherit;
            color: $primary;
            font-weight: $font-weight-bold;
        }
    }
}
