.subheader {
    position: relative;
    z-index: z('subheader');
    transform: translateZ(0);
    background-color: $white;
}

.popular-subheader {
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    font-size: 1.3em;
    color: $gray-darker;
    margin-left: 3em;
}
