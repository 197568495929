@import 'src/styles/style-importer';

.gray {
  color: $gray;
}

.gray-dark {
  color: $gray-dark;
}

.gray-darker {
  color: $gray-darker;
}
