// FIXED ASPECT RATIO (DEFAULT 16x9)
@mixin img-aspect-ratio($width: 16, $height: 9) {
    position: relative;
    overflow: hidden;
    width: 100%;
    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
}
