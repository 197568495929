// BORDERS
$border-widths: (
	0: 0,
	1: $border-width,
	2: $border-width + 1,
	3: $border-width + 2,
	4: $border-width + 3,
	5: $border-width + 4
);

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $size, $width in $border-widths {

			.b#{$infix}-#{$size},
			.bt#{$infix}-#{$size},
			.br#{$infix}-#{$size},
			.bb#{$infix}-#{$size},
			.bl#{$infix}-#{$size},
			.by#{$infix}-#{$size},
			.bx#{$infix}-#{$size} {
				border-style: solid;
				border-color: $border-color;
				border-top-width: 0;
				border-right-width: 0;
				border-bottom-width: 0;
				border-left-width: 0;
			}

			.b#{$infix}-#{$size} {
				border-top-width: $width;
				border-right-width: $width;
				border-bottom-width: $width;
				border-left-width: $width;
			}

			.bt#{$infix}-#{$size},
			.by#{$infix}-#{$size} {
				border-top-width: $width !important;
			}
			.br#{$infix}-#{$size},
			.bx#{$infix}-#{$size} {
				border-right-width: $width !important;
			}
			.bb#{$infix}-#{$size},
			.by#{$infix}-#{$size} {
				border-bottom-width: $width !important;
			}
			.bl#{$infix}-#{$size},
			.bx#{$infix}-#{$size} {
				border-left-width: $width !important;
			}
		}
	}
}