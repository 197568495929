/* NOTE: ICON SIZE IS DICTATED BY ITS PARENT */
/* SEE .btn-icon IN _buttons.scss FOR ICON SIZING IN ICON BUTTONS */

// rotate icon transition
[icon='caret-down'], [icon='angle-down'] {
    svg {
        transition: linear 0.3s;
    }
    &.active svg {
        transform: rotate(180deg);
        transition: linear 0.3s;
    }
    &.material-icons {
        transition: linear 0.3s;
        &.active {
            transform: rotate(180deg);
            transition: linear 0.3s;
        }
    }
}
[icon='caret-right']{
    svg {
        transition: linear 0.3s;
        font-size: 0.5em;
    }
    &.active svg {
        transform: rotate(90deg);
        transition: linear 0.3s;
        font-size: 0.5em;
    }
}

// favorite icon
.favorite-toggle {
    color: $dark;
    transition: all 0.3s ease-in-out;
    &:hover {
        color: $dark;
        transform: scale(1.1);
    }
}

// custom white icon with big drop shadow
.action-icon {
    color: $white;
    position: relative;
    transition: all 0.3s ease-in;
    &:hover {
        transition: all 0.3s ease-out;
        color: darken($white, 25%);
        transform: scale(1.1);
    }
    .text-primary{
        svg{
            stroke: $white;
        }
    }
    svg {
        filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
        stroke: $black;
        stroke-width: 25;
        stroke-opacity: 0.5;
    }
}

.circle-icon {
    @include circle-content();
}