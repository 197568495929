// BOOTSTRAP OPTIONS
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;

// CHANGE THE BREAKPOINTS TO ACCOUNT FOR TRUE XL SIZE
$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 992px,
    lg: 1366px,
    xl: 1920px,
);

$container-max-widths: (
    sm: 720px,
    md: 930px,
    lg: 1280px,
    xl: 1840px,
);

// BRAND COLORS PER MARKETING
$white: #ffffff;
$black: #222222;
$secondary-black: #2B2E3B;
$secondary-white: #DEE0E8;

$red-darker: #AD1F36;
$red-dark: #b22b2e; // REMAX DARK RED
$red: #dc1c2e; // REMAX PRIMARY RED
$blue-darkest: #001b49; // non-brand color from design
$blue-darker: #2e456b; // non-brand color from design
$blue-dark: #1a3668; // REMAX DARK BLUE
$blue: #003da5; // REMAX PRIMARY BLUE
$blue-medium: #2C6BE5; // non-brand color from design
$blue-alt: #004BCC;
$blue-light: #007dc3; // REMAX LIGHT BLUE
$blue-lighter: #a4d7f4; // REMAX LIGHTER BLUE
$blue-lighter-alt: #99BEFF;
$blue-lightest: #c4dff5; // non-brand color from design
$blue-lightest-hover: #f0f5ff; // non-brand color from design
$grey-darkest: #3f3f3f; // non-brand color from design
$gray-darker: #404041; // REMAX DARKER GRAY
$gray-dark: #626366; // REMAX DARK GRAY
$gray: #949ca1; // REMAX GRAY
$gray-light: #c4c6c8; // REMAX LIGHT GRAY
$gray-lighter: #efefef;
$gray-lighter-2: #DEE0E8;
$gray-lighter-3: #F6F7FB;
$gray-lighter-4: #F5F5F5;
$gray-lightest: #fafafa;
$gray-custom: #f6f8f9;
$gray-custom-2: #C2C6D6;
$gray-custom-3: #C2C5D6;
$gray-custom-4: #C0CAD9;
$black-custom-1: #292C3D;
$highlighter: #FFC499;
$charcoal: #20242D;

// Corporate Color Pallet - circa 2021
$corporate-gray-darkest: #282B39;
$corporate-gray-dark: #5A5E72;
$corporate-gray: #6E717E;
$corporate-gray-light: #f2f3f6;
$soft-white: #F2F6FF;
$corporate-green: #1C7D68;
$corporate-blue-light: #C4C7D4;

// a little arbitrary color choice
$green: #82b548;
$green-dark: #28714B;
$yellow: #ffcc00;


// Greens (progress)
$progress-green-light: #44B08C;
$progress-green: #1F956E;
$reporting-green: #ECF8F2;
$reporting-green-text: #28714B;

// Reds (reporting)
$reporting-red: #FDF2F4;
$reporting-red-text: #AD1F36;

// BYB Pill colors
$blue-todo: #143d8d;
$green-completed: #00806F;


// Design specific
$top-nav-bg: #0C2749;
$top-nav-hover: #193759;

// SEMANTIC REASSIGNMENT
$gray-100: $gray-lighter;
$gray-200: $gray-lighter;
$gray-300: $gray-light;
$gray-400: $gray-light;
$gray-500: $gray;
$gray-600: $gray;
$gray-700: $gray-dark;
$gray-800: $gray-dark;
$gray-900: $gray-darker;

// redeclare the mapping for map-get requests
$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
);

// 'flat' style buttons
$flat-primary-bg: $blue-alt;
$flat-primary-color: $white;
$flat-primary-bg-hover: #005FFF;
$flat-primary-bg-active: $blue;
$flat-secondary-bg: rgba(255, 255, 255, 0.01);
$flat-secondary-color: $blue-alt;
$flat-secondary-border-color: $gray-custom-3;
$flat-secondary-color-hover: $flat-primary-bg-hover;
$flat-secondary-bg-hover: $blue-lightest-hover;
$flat-secondary-color-active: $blue;

$primary: $blue;
$secondary: $blue-light;
$tertiary: $blue-darkest;
$quarternary: $blue-darker;
$success: $green;
$warning: $yellow;
$danger: $red;

// grays
$light: $gray-100;
$info: $gray-300;
$dark: $gray-500;

// redeclare the mapping for map-get requests
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'tertiary': $tertiary,
    'quarternary': $quarternary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
);

/******************************
    REAL WORK STARTS HERE
 ******************************/

// GLOBAL
$header-height: 60px; // because we do a lot of math for fixed positioning
$mobile-header-height: 98px; // slightly taller to adjust for search bar on new line

// Top nav
$top-nav-logo-width: 244px;
$top-nav-shrink-breakpoint: 1250px;
$top-nav-mobile-breakpoint: 992px;

$subheader-height: 100px; // subheader min height with no search filters
$footer-height: 0px; // if we have a sticky footer we need to set its height here for math
$grid-gutter-width: 40px;

$body-bg: $white;
$body-color: $gray-dark;
$border-color: $gray-light;
$shadow-color: rgba(0, 0, 0, 0.3); // custom var to keep shadows consistent similar to border-color

$box-shadow-md: 0 0.3em 0.3em $shadow-color;
$box-shadow-inactive: 0 0.125em 0.25em $shadow-color;
$box-shadow-active: 0 0.5em 1em $shadow-color;
$dashboard-box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);

$input-box-shadow-default: 0 0 0.3em 0.1em rgba(0,61,165,0.45);
$input-box-shadow-success: 0 0 0.3em 0.1em rgba(87,168,0,0.45);
$input-box-shadow-error: 0 0 0.3em 0.1em rgba(201,29,46,0.45);

$border-width: 1px;
$border-radius-sm: 2px;
$border-radius: 4px;
$border-radius-md: 0.5em;
$border-radius-lg: 5em; // use LG inputs to get the big rounded corner style input
$border-radius-top: $border-radius-md $border-radius-md 0 0;
//TYPOGRAPHY
$font-family-base: Arial, Helvetica, sans-serif;
$headings-font-family: 'Montserrat', Arial, Helvetica, sans-serif;
$raleway-font-family: 'Raleway', Arial, Helvetica, sans-serif;
$poppins-font-family: 'Poppins', Arial, Helvetica, sans-serif;
$open-sans-regular-font-family: 'Open-Sans-Regular', sans-serif;
$open-sans-semibold-font-family: 'Open-Sans-SemiBold', sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-weight-base: $font-weight-normal;
$headings-font-weight: $font-weight-bold;
$lead-font-weight: $font-weight-light;

$font-size-base: 1rem;
$font-size-sm: 0.9rem;
$font-size-lg: $font-size-base * 1.167;
$fa-font-size: $font-size-base * 1.25;

$h1-font-size: $font-size-base * 1.875;
$h2-font-size: $font-size-base * 1.333;
$h3-font-size: $font-size-base * 1.167;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 0.833;
$h6-font-size: $font-size-base * 0.778;
$lead-font-size: $font-size-base * 1.111;
$blockquote-font-size: $font-size-base;

$headings-line-height: 1.25;
$headings-color: $gray-darker;
$display-line-height: normal;
$yiq-contrasted-threshold: 200;

$link-color: inherit;
$link-hover-color: inherit;
$link-hover-decoration: underline;

// BUTTONS
$btn-border-radius-sm: $border-radius-md;
$btn-border-radius: 2em;
$btn-border-radius-lg: 3em;
$btn-box-shadow: 0 0.4em 1em $shadow-color;
$btn-padding-x: 1.5em;
$btn-padding-x-sm: $btn-padding-x;
$btn-padding-y: 0.8em;
$btn-font-weight: $font-weight-light;

// NAV
$nav-link-padding-x: $btn-padding-x;
$navbar-dark-color: $gray-100;
$navbar-dark-hover-color: $white;

// BADGES
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.5em;
$badge-border-radius: $btn-border-radius;

$badge-pill-padding-x: 1em;
$badge-pill-border-radius: $border-radius-sm;

// FORMS
$input-bg: $white;
$input-disabled-bg: $gray-100;
$input-color: $body-color;

$input-border-color: $border-color;
$input-focus-border-color: $blue-light;
$input-placeholder-color: $gray-300;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border-color;

// select input
$custom-select-bg-size: $font-size-base;
$custom-select-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%23000000' d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z'/%3E%3C/svg%3E");

// file upload input
$custom-file-width: 100%;
$custom-file-color: $input-placeholder-color;
$custom-file-button-color: $gray-dark;
$custom-file-text: (
    en: 'Choose File...',
);

// checkbox + radio
$custom-control-indicator-bg: $white;
$custom-control-indicator-size: 0.8rem;
$custom-control-indicator-bg-size: 75% 75%;
$custom-control-indicator-box-shadow: inset 0 0 1px $black;

$custom-checkbox-indicator-border-radius: 3px;
$custom-radio-indicator-icon-checked: none;

// TABS
$nav-tabs-border-color: $primary;
$nav-tabs-border-width: $border-width * 3;
$nav-tabs-border-radius: 0;

$nav-tabs-link-hover-border-color: transparent transparent $primary transparent;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: transparent transparent $primary transparent;

// CARDS
$card-bg: $white;
$card-cap-bg: $white;
$card-border-color: $border-color;

$card-border-width: $border-width;
$card-border-radius: $border-radius-md;
$card-inner-border-radius: 0;

// CARD COLUMNS
$card-columns-count: 4;
$card-spacer-y: 0.75rem;
$card-columns-margin: $card-spacer-y * 3; // card bottom margin
$card-columns-gap: $card-spacer-y * 3; // margin between columns (1.25em is default)

// MODAL
$modal-inner-padding: $card-columns-gap;
$modal-dialog-margin-y-sm-up: 3em;
$modal-border-radius: $border-radius-md;

// POPOVER
$popover-bg: $white;
$popover-header-bg: $popover-bg;
$popover-border-radius: $border-radius;
$popover-border-color: $border-color;

// JUBOTRON USED FOR AUTH HEADER
$jumbotron-padding: 2em;
$jumbotron-bg: $blue-lightest;

// TABLES
$table-cell-padding: 1%;
$table-cell-padding-sm: 0.5em;
$table-border-color: $border-color;
$table-head-bg: transparent;
$table-head-color: $dark;
$table-dark-bg: transparent;
$table-dark-border-color: transparent;

// MISC
$hr-border-color: $border-color;
$img-crop-height: 240px; // custom var for cropped/matted img height
$pagination-line-height: 1;
$dropdown-header-color: $body-color;
$margin-md: 1rem;
$margin-sm: 0.5rem;

// SOCIAL NETWORK BRAND COLORS
$facebook: #3b5998;
$facebook-light-blue: #1877F2;
$twitter: #1da1f2;
$linkedin: #0077b5;
$linkedin-blue: #0A66C2;
