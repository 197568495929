// mimic bootstrap mw-100 class
.mw-25 {
    max-width: 25% !important;
}

// similar to bs4 mw-xxx classes
.mh-33 {
    max-height: 33vh;
    overflow-y: scroll;
}

// bs4 has text-white class but no text-black class
.text-black {
    color: $black !important;
}

// attempt to center item in the viewport vertically and horizontally (e.g. LOADING ICON)
.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 20vh;
}

// instant shadow
.box-shadow {
    @include box-shadow();
}

// when we don't want the underline on a link
a.no-underline {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

// social network branding
.bg-facebook {
    background-color: $facebook;
}

.bg-linkedin {
    background-color: $linkedin;
}

.bg-twitter {
    background-color: $twitter;
}

.highlighted-text {
    background-color: $highlighter;
}