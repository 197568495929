@keyframes lds-circle {
    0% {
        transform: rotateY(0deg);
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(3600deg);
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
}

.loading-icon {
    height: (7.2em / 3);
    width: (9.6em / 3);
    background-image: url('#{$S3_PATH}/img/mxc-x-icon.svg');
    background-size: cover;
    background-position: center center;
    animation: lds-circle 6s infinite;
    margin: auto;
}