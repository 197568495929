mxc-featured {
  ngb-carousel {
    position: relative;

    .carousel-indicators {
      position: absolute;
      bottom: calc(1.25rem - 2px); // -2px to match offset heights of arrow/dots
      right: 4rem;
      justify-content: end;
      margin: 0;
      li {
        background-color: $gray-custom-2;
        width: 0.475rem;
        height: 0.475rem;
        border-radius: 50%;
      }

      .active {
        background-color: $blue;
      }
    }

    .carousel-control-prev {
      display: none;
    }

    .carousel-control-next {
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      top: unset;
      bottom: 1.25rem;
      right: 1.25rem;

      .carousel-control-next-icon {
        width: 1.5rem;
        height: 1.5rem;
        background-image: url("../../assets/icons/chevron-right.png");
      }
    }
  }
}
