.form-group {
    position: relative;
}

.form-control {
    // fake focus for style guide
    &.focus {
        border-color: $input-focus-border-color;
    } // force remove bootstrap focus shadow
    &.is-invalid {
        background: none;
    }
    &.is-invalid:focus {
        box-shadow: none;
    }
}

.custom-control-input {
    &:disabled ~ .custom-control-label {
        opacity: 0.33;
        &::before {
            opacity: 0.5;
        }
    }
}

.input-group {
    .input-group-text.is-invalid {
        @extend .form-control, .is-invalid;
    }
}

// invalid input warning icon
.validation-warning-icon {
    font-size: 1em;
    color: $danger;
    position: absolute;
    top: $font-size-base / 2;
}

.validation-success-icon {
    font-size: 1em;
    color: $green;
    position: absolute;
    top: $font-size-base / 2;
}

.validation-primary-icon {
    font-size: 1em;
    color: $primary;
    position: absolute;
    top: $font-size-base / 2;
}

label:not(.sr-only) + input {
    + .validation-warning-icon, + .validation-primary-icon, + .validation-success-icon {
        top: $font-size-base * 3;
    }
}

.input-group,
.form-control {
    + .validation-warning-icon, + .validation-primary-icon, + .validation-success-icon {
        right: $font-size-base;
    }
}

.form-group.row .form-control {
    + .validation-warning-icon, + .validation-primary-icon, + .validation-success-icon {
        right: $font-size-base * 2;
    }
}

// toggle switch
.toggle-switch {
    @include toggle-switch(33px);
    @include media-breakpoint-up(md) {
        @include toggle-switch(38px);
    }
    @include media-breakpoint-up(lg) {
        @include toggle-switch(40px);
    }
}

// ngb dropdown for select inputs
.custom-select {
    color: $input-placeholder-color;
    .dropdown-toggle {
        outline: none;
        color: $input-placeholder-color;
        width: 100%;
        display: block;
        background-origin: padding-box;
        background-color: transparent;
        text-align: left;
        border: none;
        margin: 0;
        padding: 0;
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        width: 100%;
    }
    select:focus,
    option:checked {
        color: $input-color;
    }
    .multi-select--option {
        &:hover,
        &:focus {
            background-color: $gray-lighter;
        }
    }
}

mxc-multi-select.dropdown-menu {
    height: 40vh;
    overflow-y: scroll;
    padding: 0;
}

.form-button,
a.form-button:active,
a.form-button:hover{
    border: none;
    color: $white;
    background-color: $blue;
    display: inline-block;
    margin: auto;
    text-align: center;
    padding: 8px 16px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    background: $blue-alt;
    border-radius: 4px;
    width: auto;
    text-decoration: none;

    &:disabled{
        background: $blue-lighter-alt;
        color: $soft-white;
    }

    &.full-width{
        display: block;
        width: 100%;
    }
}
