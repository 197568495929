@import '../mixins/img-crop';
@import '../mixins/img-matte';
@import '../mixins/img-aspect-ratio';

// general reset
img {
    text-align: center;
    display: block;
    width: 100%;
    font-weight: $font-weight-normal;

    // center alt tag
    &::before {
        text-align: center;
    }

    // also output the src attribute for fun
    &::after {
        font-size: $font-size-sm;
        content: '\24e7  The image at [' attr(src) '] did not load.';
        display: block;
        position: relative;
        width: 100%;
        padding: $btn-padding-y $btn-padding-x;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media-breakpoint-up(sm) {
            white-space: normal;
        }
    }
}

// force 16:9 aspect ratio
.img-fixed-aspect {
    @include img-aspect-ratio();
}
// force 1:1 aspect ratio
.img-square {
    @include img-aspect-ratio(1, 1);
    background-image: radial-gradient($gray, $gray-lighter);
}

// matte the image to fixed height
.img-matte {
    @include img-matte();
    background-image: radial-gradient($gray, $gray-lighter);
}

// crop image to fixed height
.img-crop {
    @include img-crop();
}

// placeholder glass background effect
.placeholder-bg {
    filter: blur(10px);
    opacity: 0.7;
    overflow: hidden;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: z(hidden);
    img {
        width: 200%;
        left: -50%;
    }
}