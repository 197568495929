.btn {
    text-transform: uppercase;
}

.btn-unset-transform{
    text-transform: none;
}

// big drop shadow on buttons, but not on plain text or icon buttons
.btn:not(.btn-link):not(.btn-icon):not(.btn-flat):not(disabled):not(.disabled) {
    transition: ease-in 0.3s;
    box-shadow: $box-shadow-inactive;
    &:hover, &:focus, &:active, &.active {
        transition: ease-out 0.3s;
        box-shadow: $box-shadow-active;
    }
}

// no x-padding on plain text buttons
.btn-link {
    padding: $input-btn-padding-y 0;
    fa-icon { font-size: $fa-font-size; }
}

// custom icon button class
.btn-icon {
    font-size: $fa-font-size;
    padding: 0;
    line-height: 0;
    &:hover, &:focus, &:active, &.active {
        box-shadow: none !important;
    }
}

.btn-reset {
    border-radius: 0.3rem;
    padding: 0.55rem 1rem;
    text-transform: unset;
    box-shadow: unset !important;
    &:hover, &:focus, &:active, &.active {
        transition: unset !important;
        box-shadow: unset !important;
    }
    &.btn-primary {
        &:hover, &:focus, &:active, &.active {
            background-color: #001b49 !important;
        }
    }
}

.btn-flat {
    text-transform: none;
    box-shadow: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-family: $open-sans-regular-font-family;
    font-size: 14px;
    font-weight: 600;
    &.btn-primary {
        background-color: $flat-primary-bg;
        color: $flat-primary-color;
        border: none;
        &:hover, &:focus {
            box-shadow: none !important;
            border: none;
            background: $flat-primary-bg-hover;
            color: $flat-primary-color;
        }
        &:active {
            box-shadow: none !important;
            border: none;
            background: $flat-primary-bg-active !important;
            color: $flat-primary-color !important;
        }
    }
    &.btn-secondary {
        border: 1px solid $flat-secondary-border-color;
        background: $flat-secondary-bg;
        color: $flat-secondary-color;
        &:hover, &:focus {
            box-shadow: none !important;
            border: 1px solid $flat-secondary-border-color;
            background: $flat-secondary-bg-hover;
            color: $flat-secondary-color-hover;
        }
        &:active {
            box-shadow: none !important;
            border: 1px solid $flat-secondary-border-color !important;
            background: $flat-secondary-bg-hover !important;
            color: $flat-secondary-color-active !important;
        }
    }
}