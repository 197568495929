table.table {
    th {
        border: none;
        font-weight: 400;
        text-transform: uppercase;
        background-color: $light;
        font-size: $font-size-sm;
        color: $dark;
        .btn {
            font-size: $font-size-sm;
            color: $body-color;
            fa-icon {
                font-size: $font-size-sm;
                color: $body-color;
            }
        }
        &.photo-column {
            width: 10%;
        }
    }

    td {
        line-height: normal;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
            text-decoration: underline;
        }
    }
}

.table-hover tr:not(.clickable) {
    cursor: default;
}

.table-sm {
    margin: 0;
    td, th {
        line-height: 1;
    }
}
