@import 'badges';
@import 'borders';
@import 'toggle';
@import 'z-index';
@import 'circle-content';

// FULL HEIGHT CONTAINER
/* stylelint-disable declaration-block-no-duplicate-properties */
@mixin full-height($subtractValue:0) {
    min-height: 33vh; 	// if calc is not supported (safari)
    min-height: calc(100vh - #{$subtractValue});
}
/* stylelint-enable */

// QUICK CONSISTENT SHADOWS
@mixin box-shadow( $x:0, $y:0.3em, $blur:0.3em, $color:#{$shadow-color}, $inset:null ) {
    box-shadow: $x $y $blur $color $inset;
}

@mixin valign() {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}

@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
