// FONT STACK
@font-face {
    font-family: Montserrat;
    font-weight: 300;
    font-style: normal;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-light.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-light.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-light.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    font-weight: 300;
    font-style: italic;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-lightitalic.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-lightitalic.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-lightitalic.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-regular.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-regular.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-regular.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-italic.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-italic.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-italic.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-medium.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-medium.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-medium.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    src: url('#{$S3_PATH}/fonts/montserrat/montserrat-mediumitalic.woff') format('woff'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-mediumitalic.woff2') format('woff2'),
        url('#{$S3_PATH}/fonts/montserrat/montserrat-mediumitalic.ttf') format('truetype');
}
@font-face {
    font-family: Raleway;
    font-weight: 500;
    src: url('#{$S3_PATH}/fonts/raleway/Raleway-Medium.ttf') format('truetype');
}
@font-face {
    font-family: Poppins;
    font-weight: 500;
    src: url('#{$S3_PATH}/fonts/poppins/Poppins-Medium.ttf') format('truetype');
}
@font-face {
    font-family: Poppins;
    font-weight: 400;
    src: url('#{$S3_PATH}/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: Open-Sans-Regular;
    src: url('#{$S3_PATH}/fonts/opensans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: Open-Sans-SemiBold;
    src: url('#{$S3_PATH}/fonts/opensans/OpenSans-SemiBold.ttf') format('truetype');
}
